
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      required: true,
    }
  },
  
  emits : [
    'update:modelValue'
  ],

  setup (props, { emit }) {
    const voiceTone = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    return {
      voiceTone
    }
  }
})
