
import { defineComponent, onBeforeMount, ref, watch } from 'vue'
import Multiselect from '@vueform/multiselect'
import { useCategory } from '@/composables/useCategory'
import { useI18n } from 'vue-i18n'
import BaseTextarea from '../base/BaseTextarea.vue'
import BaseInput from '../base/BaseInput.vue'
import Swal from 'sweetalert2'
import VoiceToneSelector from './VoiceToneSelector.vue'
import { availableLanguages } from '@/plugins/i18n'

interface CategoryItem {
  uid: string
  name: string
  translateKey?: string
}

export default defineComponent({
  components: {
    Multiselect,
    BaseInput,
    BaseTextarea,
    VoiceToneSelector
  },

  props: {
    form: {
      type: Object,
      required: true
    },
    currentErrors: {
      type: Object,
      default: () => {}
    }
  },

  emits: [
    'update:form'
  ],

  setup (props, { emit }) {
    const profile = ref<any>({
      category: props.form.category,
      keywords: props.form.keywords,
      website: props.form.website,
      description: props.form.description,
      specialization: props.form.specialization,
      audience: props.form.audience,
      contentPurpose: props.form.contentPurpose,
      voiceTone: props.form.voiceTone,
      profileProduct: props.form.profileProduct,
      language: props.form.language
    })

    const { getCategories } = useCategory()
    const { t } = useI18n()

    const categories = ref<CategoryItem[]>([])
    const languages = availableLanguages
      .filter((language) => language.context.includes('content'))
      .map((language) => ({
        value: language.value,
        label: language.caption,
      }))

    const selectedCategory = ref<string>(profile.value.category ? profile.value.category.uid : '')

    onBeforeMount(async () => {
      categories.value = await getCategories()
    })

    watch(selectedCategory, (categoryId) => {
      profile.value.category = categories.value.find((category) => category.uid === categoryId)
    })

    const addKeyword = (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      const keywordInput = element.querySelector('input')
      if (!keywordInput) {
        return
      }

      const inputValue = keywordInput.value
      if (profile.value.keywords.length < 3 && !!inputValue) {
        if (!profile.value.keywords.find((key: string) => key === inputValue)) {
          profile.value.keywords.push(inputValue)
        }
        keywordInput.value = ''
      }
    }

    const removeKeyword = (arrayIndex: number) => {
      profile.value.keywords.splice(arrayIndex, 1)
    }

    const openCategoryInfo = () => {
      Swal.fire({
        title: t('state.settings.category.about_category.titlle'),
        confirmButtonText: t('state.settings.category.about_category.action_confirm'),
        html: t('state.settings.category.about_category.message-html')
      })
    }

    const openKeywordInfo = () => {
      Swal.fire({
        title: t('state.settings.category.about_keywords.titlle'),
        confirmButtonText: t('state.settings.category.about_keywords.action_confirm'),
        html: t('state.settings.category.about_keywords.message-html')
      })
    }

    watch(profile, (newValue) => {
      emit('update:form', {
        ...newValue,
        ...(newValue.website && {website: newValue.website?.trim().length && !newValue.website.startsWith('http') ? `https://${newValue.website?.trim()}` : newValue.website?.trim()})
      })
    }, { deep: true })

    return {
      profile,
      categories,
      selectedCategory,
      languages,
      addKeyword,
      removeKeyword,
      openCategoryInfo,
      openKeywordInfo
    }
  }
})
